<template>
    <div id="dibs-complete-checkout"></div>
</template>

<script>
    import { mapState } from 'vuex';
    import paymentService from '@/services/paymentService';
    import router from '@/router';

    export default {
        props: ["gateId"],
        data() {
            return {
                netsModel: {}
            };
        },
        methods: {
            async fetchNetsModel() {
                this.netsModel = await paymentService.getPayment(this.gateId);
                if (!this.netsModel) {
                    console.error('Error: Check output from getPayment');
                    return;
                }
                this.netsModel.netsPaymentId = await paymentService.createNetsPayment(this.gateId);
                if (!this.netsModel.netsPaymentId) {
                    console.error('Error: Check output from createNetsPayment');
                    return;
                }
            },
            makeNetsPayment() {
                const checkoutOptions = {
                    checkoutKey: this.netsModel.checkoutKey,
                    paymentId: this.netsModel.netsPaymentId,
                    containerId: "dibs-complete-checkout",
                    language: this.language,
                    theme: {
                        textColor: "",
                        primaryColor: "",
                        buttonRadius: "5px",
                        buttonTextColor: "",
                        linkColor: "",
                        footerBackgroundColor: "",
                        footerOutlineColor: "",
                        footerTextColor: "",
                        backgroundColor: "",
                        panelColor: "",
                        outlineColor: "",
                        primaryOutlineColor: "",
                        panelTextColor: "",
                        panelLinkColor: "",
                        fontFamily: "Roboto",
                        buttonFontWeight: 500,
                        buttonFontStyle: "italic",
                        buttonTextTransform: "none",
                        placeholderColor: "",
                        useLightIcons: false,
                        useLightFooterIcons: false
                    }
                };
                /*eslint-disable */
                const checkout = new Dibs.Checkout(checkoutOptions);
                /* eslint-enable */

                checkout.on('payment-completed', (response) => {
                    paymentService.checkoutCompleted(response.paymentId.toString())
                        .then((data) => {
                            //router.push({ name: 'editGate', params: { gateId: this.gateId } })
                            router.push({ name: 'editGate', params: { gateId: this.gateId }, query: { tabIndex: 4 } });
                        })
                        .catch(function (error) {
                            alert(error);
                        });
                });
            },
            async loadDibsCheckoutScript() {
                await this.fetchNetsModel()
                const script = document.createElement("script");
                script.src = this.netsModel.checkoutUrl;
                script.async = true;
                script.onload = () => {
                    this.makeNetsPayment();
                };
                document.body.appendChild(script);
            }
        },
        mounted() {
            this.loadDibsCheckoutScript();
        },
        computed: {
            ...mapState('authentication', {
                stateUser: 'user',
            }),
        },
    };
</script>
