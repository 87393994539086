"use strict";

import axios from "axios";
var baseControllerAddress = 'payment/';

export default {
    getPayment: (gateId) => {
        return axios.get('/api/' + baseControllerAddress + "Nets/" + gateId)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    createNetsPayment: (gateId) => {
        return axios.get('/api/' + baseControllerAddress + "CreatePayment/" + gateId)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    checkoutCompleted: (paymentId) => {
        return axios.get('/api/' + baseControllerAddress + "netsPaymentAccepted/" + paymentId)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    setNotificationTypes: (gateUserId, notificationTypesModels) => {
        return axios.post('/api/' + baseControllerAddress + "SetNotificationTypes/", notificationTypesModels, { params: { gateUserId } })
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    }

}